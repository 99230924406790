<template>
  <div class="about">
    <div class="ing" v-show="!finishPrint">
      <div class="doing">
        <van-image
          width="100"
          height="100"
          :src="
            isPrint
              ? require('@/assets/images/curCase.png')
              : require('@/assets/images/printer.png')
          "
        />
      </div>
      <div class="doingText" v-if="!isPrint">文件排队打印中...</div>
      <div class="doingText" v-if="isPrint">学案创建中...</div>
    </div>
    <div class="ed" v-show="finishPrint">
      <div class="doing">
        <van-image
          width="100"
          height="100"
          :src="require('@/assets/images/T.png')"
        />
      </div>
      <div class="doingText edText" v-if="!isPrint">已经打印完成</div>
      <div class="doingText edText" v-if="isPrint">已经创建完成</div>
      <div class="doingText" v-if="!isPrint">
        请移步至<span style="color: rgb(255, 118, 0)">3楼打印室</span
        >取回您的文件~
      </div>
      <div class="backBtn doingText">
        <van-button type="success" size="mini" @click="goBack"
          >返回主页</van-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, onBeforeMount } from "vue";
import { Toast } from "vant";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { apiTaskStatus } from "@/service/teacher1.4.js";

const taskId = localStorage.getItem("taskId");

const router = useRouter();
const emit = defineEmits(["changeActive"]);

let isPrint = ref(+localStorage.getItem("type") == 3);
onBeforeMount(() => {
  if (!localStorage.getItem("file")) {
    Toast.fail("没有打印信息");
    router.push("/teacher/printCenter");
  }
});

let timer = null;
let finishPrint = ref(false);
if (!isPrint.value) {
  timer = setInterval(async () => {
    let res = await apiTaskStatus({ taskId: taskId });
    if (res.data.print_status == 2) {
      window.clearInterval(timer);
      emit("changeActive", 3);
      finishPrint.value = true;
      localStorage.removeItem("active");
      localStorage.removeItem("setting");
      localStorage.removeItem("file");
      localStorage.removeItem("type");
      localStorage.removeItem("taskId");
    }
  }, 2000);
} else {
  finishPrint.value = true;
  emit("changeActive", 3);
}

const goBack = () => {
  router.push({ path: "/teacher/printCenter" });
};

onBeforeRouteLeave(() => {
  localStorage.removeItem("active");
  localStorage.removeItem("setting");
  localStorage.removeItem("file");
  localStorage.removeItem("type");
  localStorage.removeItem("taskId");
  window.clearInterval(timer);
});
</script>

<style lang="less" scoped>
.doing {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.doingText {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  color: #18a4e0;
}
.edText {
  color: rgb(104, 210, 121);
}
</style>
